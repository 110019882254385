<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.contracts") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="contractsJSON"
      :loading="loading"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field
                onkeypress="return (event.charCode != 34)"
                v-on:keyup.86="replaceSearch"
                v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="d-flex justify-end text-end">
              <LoginMetamask view="TOKEN" />
              <span class="mx-2"/>
              <ContractCreateComponent v-if="grant" />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.contractAddress="{ item }">
        <a :href="`${amoyNet}/address/${item.contractAddress}`" target="_blank">
          <p class="d-flex justify-start align-center align-content-center ma-0 px-2 py-1 token-active" v-if="item.status === 'RUNNING'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="success" small right>
              mdi-link
            </v-icon>
          </p>
          <p class="d-flex justify-space-between align-center align-content-center ma-0 px-2 py-1 token-inactive" v-if="item.status === 'STOPPED'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="error" small right>
              mdi-link
            </v-icon>
          </p>
          <p class="d-flex justify-space-between align-center align-content-center ma-0 px-2 py-1 token-pending" v-if="item.status === 'PENDING'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="warning" small right>
              mdi-link
            </v-icon>
          </p>
        </a>
      </template>
      <template v-slot:item.totalSupply="{ item }">
        {{ item.totalSupply }}
      </template>
      <template v-slot:item.company="{ item }">
        {{ item.company.name }}
      </template>
      <template v-slot:item.action="{ item }">
        <!-- ContractHistory -->
        <ContractHistoryComponent :contract="item" />
        <!-- DialogTokenOptions -->
        <ContractConfigComponent :contract="item" v-if="grant" />
      </template>
    </v-data-table>
    <TimeSyncedComponent section='CONTRACTS' :box-styles='boxStyles' :text-styles='textStyles'/>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n"
import Web3  from "web3";
import { mapActions, mapGetters } from "vuex";
import factoryJSON from "../../../../build/contracts/Factory.json";
import ERC1155ModelJSON from "../../../../build/contracts/ERC1155Model.json";
import LoginMetamask from "../commons/LoginMetamask";
import ContractCreateComponent from "@/module/tokens/contract/components/ContractCreateComponent";
import ContractHistoryComponent from "@/module/tokens/contract/components/ContractHistoryComponent";
import ContractConfigComponent from "@/module/tokens/contract/components/ContractConfigComponent";
import TimeSyncedComponent from '@/module/tokens/commons/TimeSyncedComponent'

export default {
  name: "IndexView",
  components: {
    TimeSyncedComponent,
    ContractHistoryComponent,
    ContractConfigComponent,
    ContractCreateComponent,
    LoginMetamask,
  },

  data: () => ({
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [5, 10, 20, 50, 100, 200],
    },
    profile: JSON.parse(localStorage.getItem("profile")) || null,
    web3js: new Web3(window.ethereum),
    product: {
      own: null,
      symbol: null,
    },
    selectedToken: null,
    walletAddress: null,
    role: null,
    search: null,
    loading: false,
    valid: false,
    amoyNet: process.env.VUE_APP_AMOY_SCAN,
    boxStyles: 'background: #005b4a; padding: 5px 20px; border-radius: 7px 7px 0 0;',
    textStyles: 'color: white; font-size: 12px;'
  }),

  computed: {
    ...mapGetters({
      web3: "web3/GET_WEB3",
      factory: "web3/GET_FACTORY",
      contracts: "web3/GET_CONTRACTS_LIST",
      contractsJSON: "web3/GET_CONTRACTS_JSON_LIST",
      getTotalMinted: "web3/GET_TOTAL_MINTED",
      isOwner: "web3/GET_FACTORY_OWNER",
    }),
    headers() {
      return [
        {
          text: i18n.t('tokens.fields.contract'), value: "contractAddress", val: "contractAddress", width: 220
        },
        {
          text: i18n.t("tokens.fields.token"), value: "product", val: "product", width: 200
        },
        {
          text: i18n.t("tokens.fields.symbol"), value: "symbol", val: "symbol", width: 120
        },
        {
          text: i18n.t("inventory.fields.issued_tokens"), value: "ownerSupply", val: "ownerSupply", width: 150
        },
        {
          text: i18n.t("tokens.fields.active_token"), value: "active", val: "active", width: 150
        },
        {
          text: i18n.t("modules.name.Company"), value: "company", val: "company", width: 150
        },
        {
          text: i18n.t("general.fields.action"), value: "action", width: 150, sortable: false, align: "start"
        },
      ]
    },
    grant() {
      return (this.web3.account && this.isOwner) && this.profile.company_id === 1;
    },
  },

  async mounted() {
    await this.initialize();

    window.ethereum.on("accountsChanged", async () => {
      this.$store.commit("web3/DEFAULT_CONTRACTS_LIST");
      await this.initialize();
    });

    this.$root.$on("initializeTokenData", async () => {
      await this.initialize();
    });

    this.$root.$on("closeTokenData", async () => {
      this.search = null;
      this.$store.commit("web3/DEFAULT_CONTRACTS_LIST");
    });
  },

  methods: {
    ...mapActions({
      isFactoryOwner: "web3/CHECK_FACTORY_OWNER",
      ifUpdateAddress: "web3/IF_UPDATE_ADDRESS",
      loadFactoryData: "web3/LOAD_FACTORY_DATA",
      startListeningWalletChanges: "web3/START_LISTENING_WALLET_CHANGES",
      fetchContractsJson: "web3/FETCH_CONTRACTS_JSON",
      fetchContracts: "web3/FETCH_CONTRACTS",
      fetchWallets: 'web3/FETCH_WALLETS',
    }),
    async initialize() {
      this.loading = true;

      if (!window.ethereum && !window.userAddress) {
        console.log("Não foi detectado a extenção ou conexão com a Metamask.");
      }

      await this.web3js.eth.getAccounts().then((accounts) => {
        window.userAddress = accounts[0];
        console.log(accounts)
        this.$store.commit("web3/REGISTER_WEB3_INSTANCE", accounts[0]);
        localStorage.setItem("eth_account", accounts[0]);
        this.ifUpdateAddress().then(console.log);
      });

      this.startListeningWalletChanges()

      await this.loadFactoryData({ factoryJSON, ERC1155ModelJSON });

      if (window.userAddress) {
        await this.isFactoryOwner();
      }

      await this.fetchContractsJson().finally(() => {
        this.loading = false;
      });  

      await this.fetchWallets()
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value
        .toString()
        .replaceAll('"', "");
    },
    replaceSearch(event) {
      this.search = event.currentTarget.value.toString().replaceAll('"', "");
    },
  },

  destroyed() {
    this.$store.commit("web3/DEFAULT_CONTRACTS_LIST");
    this.$root.$off("initializeTokenData");
    this.$root.$off("closeTokenData");
  },
};
</script>

<style scoped>
.zebra-bg tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

a {
  text-decoration: none !important;
}
</style>
