<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      persistent
      @click:outside="closeDialog()"
  >
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
              @click="showDialog()"
              v-on="on"
              color="neuter"
              class="mr-2 zoom__btn"
              small
          >
            mdi-cog-outline
          </v-icon>
        </template>
        <span>
            {{ $t('tokens.buttons.config') }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t('modules.name.TokenWallet') }}
            </span>
          </span>
      </v-tooltip>
    </template>

    <v-card v-if="selectedToken">
      <v-overlay :value="launchLoadingMetamask" absolute color="white" :opacity="0.9">
        <p class="text--disabled text-end ma-0 pa-0 code-metamask">{{ $t('tokens.titles.waitingFor') }}</p>
        <v-img :src="require('@/assets/img/metamask-logo.svg')" contain/>
        <v-progress-linear class="mt-1" indeterminate color="#f5841f"/>
      </v-overlay>
      <v-card-title
          class="text-h5 d-flex justify-space-between align-center align-content-center secondary white--text text-uppercase">
          {{ $t('tokens.titles.contractConfig') }}
      </v-card-title>
      <v-card-text class="pa-6">
        <v-form v-model="valid">
          <div class="d-flex justify-start align-center align-content-center">
            <p class="ma-0 px-2 py-1 token-name"
               :style="`width: ${selectedToken.product.length*10}`"
            >
              {{ selectedToken.product | truncate(20) }}
            </p>
            <div>
              <p v-if="selectedToken.status === 'RUNNING'" class="ma-0 px-2 py-1 token-address__active">
                {{ selectedToken.contractAddress | truncateAddress(10) }} </p>
              <p v-if="selectedToken.status === 'STOPPED'" class="ma-0 px-2 py-1 token-address__inactive">
                {{ selectedToken.contractAddress | truncateAddress(10) }} </p>
              <p v-if="selectedToken.status === 'PENDING'" class="ma-0 px-2 py-1 token-address__pending">
                {{ selectedToken.contractAddress | truncateAddress(10) }} </p>
            </div>
          </div>
          <v-row class="my-4">
            <v-col cols="8">
              <v-select
                  :disabled="selectedToken.status === 'PENDING'"
                  :placeholder="$t('tokens.fields.walletAddress')"
                  :items="wallets"
                  item-text="name"
                  return-object
                  v-model="wallet"
                  clearable
                  small
                  outlined
                  dense
                  color="primary"
                  item-color="primary"
                  :rules="requireRules"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                  small
                  class="mt-0"
                  tile
                  :elevation="0"
                  :color="verifyColor()"
                  style="border-radius: .2em;"
                  @click="verifyRole"
                  :loading="loadingVerifyRole"
                  :disabled="!wallet || !role || !validateAddressWallet(wallet.address)"
                  height="40"
                  width="100%"
              >
                <template v-slot:loader>
                  <v-progress-circular color="primary" indeterminate size="12" width="3"></v-progress-circular>
                </template>
                <template v-slot:default>
                  <v-slide-x-transition v-if="isVerify === null">
                        <span class="d-flex justify-center align-center align-content-center">
                          <p class="pa-0 ma-0">{{ $t('tokens.buttons.verifyRole') }}</p>
                          <v-icon color="neuter" right>mdi-chevron-right</v-icon>
                         </span>
                  </v-slide-x-transition>
                  <v-slide-x-transition v-else-if="isVerify === false">
                        <span class="d-flex justify-center align-center align-content-center">
                          <p class="pa-0 ma-0">{{ $t('tokens.buttons.notFound') }}</p>
                          <v-icon right>mdi-close</v-icon>
                        </span>
                  </v-slide-x-transition>
                  <v-slide-x-transition v-else>
                        <span class="d-flex justify-center align-center align-content-center">
                          <p class="pa-0 ma-0">{{ $t('tokens.buttons.found') }}</p>
                          <v-icon right>mdi-check</v-icon>
                        </span>
                  </v-slide-x-transition>
                </template>
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex flex-column justify-start">
              <p class="title ma-0 pa-0">
                {{ $t('tokens.titles.selectRole') }}
              </p>
              <v-chip-group
                  id="chip-group__tokens"
                  v-model="role"
                  color="primary"
              >
                    <span v-for="role in roles">
                      <v-chip :disabled="selectedToken.status === 'PENDING'"
                              :value="role"
                      >
                        {{ $t(role.name) }}
                      </v-chip>
                    </span>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn
          small
          v-if="selectedToken.status === 'PENDING'"
          text
          :disabled="loading"
          @click="initContractAction()"
          class="ml-1 pending"
          :loading="loadings.pending"
        >
          <template v-slot:default>
            {{ $t('tokens.buttons.init') }}
            <v-icon small right>
              mdi-hand-wave-outline
            </v-icon>
          </template>
        </v-btn>
        <v-btn
            small
            v-if="selectedToken.status === 'RUNNING'"
            color="error"
            elevation="0"
            :disabled="loading"
            @click="stopContractAction()"
            class="ml-1 t-bw-error--text"
            :loading="loadings.pause"
        >
          <template v-slot:default>
            {{ $t('tokens.buttons.stop') }}
            <v-icon small right>
              mdi-hand-back-right-outline
            </v-icon>
          </template>
        </v-btn>
        <v-btn
            small
            v-if="selectedToken.status === 'STOPPED'"
            color="success"
            elevation="0"
            :disabled="loading"
            @click="startContractAction()"
            class="ml-1 t-bw-success--text"
            :loading="loadings.start"
        >
          <template v-slot:default>
            {{ $t('tokens.buttons.start') }}
            <v-icon small right>
              mdi-play-speed
            </v-icon>
          </template>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            small
            text
            :disabled="loading || (loadings.start || loadings.pause ||  loadings.pending) || loadingVerifyRole"
            @click="closeDialog()"
            class="mr-1 btn-bg-red"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <v-btn
            small
            v-if="!isVerify"
            class="t-bw-success--text"
            color="success"
            elevation="0"
            :disabled="!valid || (loadings.start || loadings.pause) || loadingVerifyRole"
            @click="savePermissions()"
            :loading="loading"
        >
          {{ $t('general.buttons.grant') }}
        </v-btn>
        <v-btn
            small
            v-else
            class="t-bw-error--text"
            elevation="0"
            color="error"
            :disabled="!valid || (loadings.start || loadings.pause) || loadingVerifyRole"
            @click="revokePermissions()"
            :loading="loading"
        >
          {{ $t('general.buttons.revoke') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ContractConfigComponent",

  props: {
    contract: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      dialog: false,
      loading: false,
      launchLoadingMetamask: false,
      loadingVerifyRole: false,
      isVerify: null,
      valid: false,
      selectedToken: null,
      wallet: null,
      role: null
    }
  },

  watch: {
    role() {
      this.isVerify = null
      this.loadingVerifyRole = false
    },
    wallet() {
      this.isVerify = null
      this.loadingVerifyRole = false
    }
  },

  computed: {
    ...mapGetters({
      getTotalMinted: 'web3/GET_TOTAL_MINTED',
      roles: 'web3/GET_ROLES',
      wallets: 'web3/GET_WALLETS',
      validateAddress: 'general/ethereumAddress',
      requireRules: 'general/requireRules',
      loadings: 'web3/GET_LOADINGS',
    })
  },

  created() {
    this.refactorToken()
  },

  methods: {
    ...mapActions({
      initContract: 'web3/INIT_CONTRACT',
      startContract: 'web3/START_CONTRACT',
      stopContract: 'web3/STOP_CONTRACT',
      grantRole: 'web3/GRANT_ROLE',
      revokeRole: 'web3/REVOKE_ROLE',
      hasRole: 'web3/HAS_ROLE'
    }),
    async savePermissions() {
      this.loading = true

      await this.grantRole({
        contractAddress: this.selectedToken.contractAddress,
        roleAddress: this.role.address,
        wallet: this.wallet,
        role: {
          contractId: this.selectedToken.id,
          walletId: this.wallet.id,
          roleId: this.role.id
        }
      }).finally(() => {
        this.loading = false
        this.closeDialog()
      })
    },
    async revokePermissions() {
      this.loading = true

      await this.revokeRole({
        contractAddress: this.selectedToken.contractAddress,
        roleAddress: this.role.address,
        wallet: this.wallet,
        role: {
          contractId: this.selectedToken.id,
          walletId: this.wallet.id,
          roleId: this.role.id
        }
      }).finally(() => {
        this.loading = false
        this.closeDialog()
      })
    },
    async startContractAction() {
      this.loadings.start = true

      await this.startContract(this.selectedToken).finally(() => {
        setTimeout(() => {
          const result = _.find(this.contracts, {'contractAddress': this.selectedToken.contractAddress})

          if (result)
            this.selectedToken = result

          this.loadings.start = false
        }, 2000)
      })
    },
    async initContractAction() {
      this.loadings.pending = true

      await this.initContract(this.selectedToken).finally(() => {
        setTimeout(() => {
          const result = _.find(this.contracts, {'contractAddress': this.selectedToken.contractAddress})

          if (result)
            this.selectedToken = result

          this.loadings.pending = false
        }, 2000)
      })
    },
    async stopContractAction() {
      this.loadings.pause = true

      await this.stopContract(this.selectedToken).finally(() => {
        setTimeout(() => {
          const result = _.find(this.contracts, {'contractAddress': this.selectedToken.contractAddress})

          if (result)
            this.selectedToken = result

          this.loadings.pause = false
        }, 2000)
      })
    },
    async verifyRole() {
      console.log(this.role)
      if (this.role) {
        this.loadingVerifyRole = true

        this.isVerify = await this.hasRole({
          contractAddress: this.selectedToken.contractAddress,
          roleAddress: this.role.address,
          wallet: this.wallet
        })

        this.loadingVerifyRole = false
      }
    },
    showDialog() {
      this.refactorToken()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.selectedToken = null
      this.wallet = null
      this.role = null
    },
    refactorToken() {
      this.selectedToken = _.cloneDeep(this.contract)
    },
    validateAddressWallet() {
      return (address) => /^0x[a-fA-F0-9]{40}$/.test(address);
    },
    verifyColor() {
      if (this.isVerify === null) {
        return 'gray'
      } else if (this.isVerify === false) {
        return 'error'
      } else {
        return 'success'
      }
    },
  }
}
</script>

<style scoped>
.token-name {
  background: rgba(0, 0, 0, .07);
  color: #1a1a1a;
  border-radius: .3em 0 0 .3em;
  font-size: 12px;
  font-weight: 500;
}

.token-address__active,
.token-address__inactive,
.token-address__pending
{
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
}

.token-address__active {
  background: rgb(3 172 140 / 7%);
  color: rgb(15 155 128 / 70%);
}

.token-address__inactive {
  background: rgb(255 0 0 / 7%);
  color: #f10303;
}

.token-address__pending {
  background: rgba(255, 60, 0, 0.07);
  color: #f1ae03;
}

.pending {
  color: #f1ae03 !important;
}
</style>